import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "National University of Singapore",
    years: "2013 - 2017",
    content: "Bachelor's degree, Computer Science with Minor in Mathematics",
  },
  {
    id: 2,
    title: "Singapore Polytechnic",
    years: "2010 - 2013",
    content: "Diploma (with Merit), Clean Energy",
  },
];

const experienceData = [
  {
    id: 1,
    title: "Engineer @ Arta Finance",
    years: "Aug 2022 - Present",
    content: "Mobile App Development • Flutter • Dart",
  },
  {
    id: 2,
    title: "Full Stack Engineer @ StashAway",
    years: "Jan 2022 - Jun 2022",
    content: "Mobile App Development • React Native • JavaScript",
  },
  {
    id: 3,
    title: "Software Engineer @ Datavis",
    years: "Apr 2019 - Jan 2022",
    content: "Web Development • React.js • JavaScript • GraphQL • Hasura",
  },
  {
    id: 4,
    title: "Research Assistant @ SUTD",
    years: "Jul 2017 - Mar 2019",
    content:
      "Mobile App Development • Web Development • Three.js • React Native • JavaScript",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} data={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} data={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
