import React from "react";

import { ReactComponent as ApiSvg } from "../../images/icons/icons8-api-50.svg";
import { ReactComponent as BinaryCodeSvg } from "../../images/icons/icons8-binary-code-50.svg";
import { ReactComponent as BotSvg } from "../../images/icons/icons8-bot-50.svg";
import { ReactComponent as BugSvg } from "../../images/icons/icons8-bug-50.svg";
import { ReactComponent as CmdSvg } from "../../images/icons/icons8-cmd-50.svg";
import { ReactComponent as ConsoleSvg } from "../../images/icons/icons8-console-50.svg";
import { ReactComponent as DevModeSvg } from "../../images/icons/icons8-developer-mode-50.svg";
import { ReactComponent as IcedCoffeeSvg } from "../../images/icons/icons8-iced-coffee-50.svg";
import { ReactComponent as JavascriptSvg } from "../../images/icons/icons8-javascript-50.svg";
import { ReactComponent as SourceCodeSvg } from "../../images/icons/icons8-source-code-50.svg";
import { ReactComponent as StackOverflowSvg } from "../../images/icons/icons8-stack-overflow-50.svg";

function Parallax(props) {
  const { x, y } = props.position;
  const { height, width } = props.elementDimensions;
  const activeParallax = (depth = 15) => {
    let posX = (width / 2 - x) / depth;
    let posY = (height / 2 - y) / depth;
    return {
      transform: `translate(${posX}px, ${posY}px) rotate(${posX}deg)`,
    };
  };

  return (
    <div className="parallax" data-relative-input="true">
      <ApiSvg style={activeParallax(15)} className="layer p1" />
      <BinaryCodeSvg style={activeParallax(20)} className="layer p2" />
      <DevModeSvg style={activeParallax(25)} className="layer p3" />
      <BugSvg style={activeParallax(10)} className="layer p4" />
      <CmdSvg style={activeParallax(8)} className="layer p5" />
      <ConsoleSvg style={activeParallax(18)} className="layer p6" />
      <BotSvg style={activeParallax(22)} className="layer p7" />
      <IcedCoffeeSvg style={activeParallax(15)} className="layer p8" />
      <JavascriptSvg style={activeParallax(10)} className="layer p9" />
      <SourceCodeSvg style={activeParallax(23)} className="layer p10" />
      <StackOverflowSvg style={activeParallax(18)} className="layer p11" />
    </div>
  );
}

export default Parallax;
