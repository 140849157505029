import React from "react";
import ReactCursorPosition from "react-cursor-position";
import { Element } from "react-scroll";
import Experiences from "../components/sections/Experiences";
import Herosection from "../components/sections/Herosection";

function Homepage() {
  return (
    <main>
      <div className="background" />
      <Element name="section-home">
        <ReactCursorPosition>
          <Herosection />
        </ReactCursorPosition>
      </Element>
      <Element name="section-experiences">
        <Experiences />
      </Element>
      <div className="spacer" data-height="96"></div>
    </main>
  );
}

export default Homepage;
