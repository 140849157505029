import React from "react";
import Typed from "react-typed";
import { useLottie } from "lottie-react";

import Parallax from "./Parallax";
import linkedinAnimation from "../../lotties/linkedin.json";

function Herosection(props) {
  const { View } = useLottie({
    animationData: linkedinAnimation,
    loop: true,
  });

  return (
    <section
      id="home"
      className={
        props.light
          ? "home d-flex align-items-center light"
          : "home d-flex align-items-center"
      }
    >
      <div className="background" />
      <div className="container">
        <div className="intro">
          <img
            src="images/profile_pic.png"
            alt="Lin Chen-Hsin"
            className="mb-4 profile-pic"
          />

          <h1 className="mb-2 mt-0">
            Hi, I'm <span className="emphasis">Chen-Hsin</span>
          </h1>
          <p className="typing">
            I'm a{" "}
            <Typed
              strings={[
                "Frontend Developer",
                "Web App Developer",
                "Mobile App Developer",
              ]}
              typeSpeed={80}
              backSpeed={40}
              loop
            />
          </p>

          <div className="spacer" data-height="30"></div>
          <p>Engineer @ Arta Finance</p>
          <p>Loves to create beautiful websites and mobile apps</p>

          <ul className="social-icons light list-inline mb-0 mt-4">
            <li className="list-inline-item">
              <a
                href="https://www.linkedin.com/in/linchenhsin/"
                target="_blank"
                rel="noreferrer"
                id="linkedin"
              >
                {View}
              </a>
            </li>
          </ul>
        </div>
        <Parallax
          position={props.position}
          elementDimensions={props.elementDimensions}
        />
      </div>
    </section>
  );
}

export default Herosection;
