import React from "react";

function Timeline({ data }) {
  const { years, title, content } = data;
  return (
    <div className="timeline-container">
      <div className="content">
        <span className="time">{years}</span>
        <h3 className="title">{title}</h3>
        <p>{content}</p>
      </div>
    </div>
  );
}

export default Timeline;
